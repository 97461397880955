import React from 'react';

export default function About() {
    return (
        <div>

            <div className="container my-5" style={{maxWidth: "1000px"}}>
                <h2>Accessibility Statement</h2>
                <p>
                    pending statement
                </p>
            </div>
        </div>
    );
}